import '@/scss/styles.scss';
import configService from "./plugins/config-service";
import MobileConnectService from './plugins/mobile-connect';
import webSocketService from "./plugins/websocket-service";

document.addEventListener("DOMContentLoaded", async function(event) {
    await configService.loadRuntimeConfig();

    const init = () => {
        const videoHolder = document.querySelector('.js-video-holder');
        const videoPopup = document.querySelector('.js-popup');
        const body = document.querySelector('body');
        const video = document.querySelector('.js-video');
        const videoLoader = document.querySelector('.js-video-loader');
        const tvButtonLink = document.querySelector('.js-go-game-link');

        tvButtonLink.setAttribute('href', `${configService.getConfig()?.TV_BASE_URL}/` ?? '')

        const fixedBody = () => {
            body.classList.add('fixed');
        };

        const unFixedBody = () => {
            body.classList.remove('fixed');
        };

        const playVideo = () => {
            video.play();
            videoLoader.style.display = 'none';
        };

        videoHolder.addEventListener('click', () => {
            video.load();
            video.addEventListener('canplaythrough', playVideo);
            videoPopup.style.display = 'flex';
            fixedBody();
        });

        video.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        videoPopup.addEventListener('click', () => {
            video.removeEventListener('canplaythrough', playVideo);
            video.load();
            video.pause();
            video.currentTime = 0;
            videoPopup.style.display = 'none';
            unFixedBody();
        });

        const swiper = new Swiper('.swiper', {
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,

            centeredSlides: true,
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                },
                1919: {
                    slidesPerView: 'auto',
                },
            },
        });

        const mobileConnect = new MobileConnectService(configService, webSocketService);

        const HAS_ERROR = 'has-error';
        const SUBSCRIBE_SUCCESS = 'subscribe-success';

        const subscribeForm = document.querySelector('.js-subscribe-form');
        const subscribeFormWrapper = subscribeForm.querySelector('.js-subscribe-form-wrapper');
        const submitFormButtonText = subscribeForm.querySelector('.js-subscribe-submit-text');
        const emailField = subscribeForm.querySelector('.js-email');
        const subscribeUrl = `${configService.getConfig()?.ADMIN_BASE_URL ?? ''}/api/subscriber/create`;
        const errorHolder = subscribeForm.querySelector('.js-error-holder');
        const errorText = subscribeForm.querySelector('.js-error-text');

        /**
         * Показать ошибку
         * @param error
         */
        const showError = (error) => {
            if (typeof error.message.email[0] !== 'undefined') {
                errorText.innerHTML = error.message.email[0];
            }
            errorHolder.classList.add(HAS_ERROR);
        };

        let handleSubscribeSuccessTimeoutId = null;

        /**
         * Обработать успешную подписку
         */
        const handleSubscribeSuccess = () => {
            submitFormButtonText.innerHTML = 'You subscribed!';
            subscribeFormWrapper.classList.add(SUBSCRIBE_SUCCESS);

            if (handleSubscribeSuccessTimeoutId) {
                clearTimeout(handleSubscribeSuccessTimeoutId);
            }

            handleSubscribeSuccessTimeoutId = setTimeout(() => {
                submitFormButtonText.innerHTML = 'Subscribe';
                subscribeFormWrapper.classList.remove(SUBSCRIBE_SUCCESS);
            }, 2000);

        };

        emailField.addEventListener('input', () => {
            errorHolder.classList.remove(HAS_ERROR);
        });

        /**
         * Форма подписки
         */
        subscribeForm.addEventListener('submit', (e) => {
            e.preventDefault();
            try {
                const params = { email: emailField.value, language: 'en' };
                fetch(subscribeUrl, {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then( (response) => response.json())
                    .then(data => {
                        if (data.error) {
                            showError(data.error);
                        } else {
                            handleSubscribeSuccess();
                        }
                    });
            } catch (error) {
                console.error('Ошибка:', error);
                showError(error);
            }
        });

        const OPENED_CLASS_DONT_KNOW_CODE_POPUP = 'opened';
        const dontKnowCodePopup = document.querySelector('.js-dont-know-code-popup');
        const buttonOpenDontKnowCodePopup = document.querySelector('.js-dont-know-code-popup-open-popup');
        const buttonCloseDontKnowCodePopup = document.querySelector('.js-dont-know-code-popup-close-popup');

        const openDontKnowCodePopup = () => {
            dontKnowCodePopup.classList.add(OPENED_CLASS_DONT_KNOW_CODE_POPUP);
            body.classList.add('code-popup-open');
            fixedBody();
        };

        const closeDontKnowCodePopup = () => {
            dontKnowCodePopup.classList.remove(OPENED_CLASS_DONT_KNOW_CODE_POPUP);
            body.classList.remove('code-popup-open');
            unFixedBody();
        }

        const clickButtonOpenDontKnowCodeHandler = () => {
            openDontKnowCodePopup();
        };

        const clickButtonCloseDontKnowCodeHandler = () => {
            closeDontKnowCodePopup();
        };

        buttonOpenDontKnowCodePopup.addEventListener('click', clickButtonOpenDontKnowCodeHandler);
        buttonCloseDontKnowCodePopup.addEventListener('click', clickButtonCloseDontKnowCodeHandler);
    }

    init();
});
