import configService from "./config-service";

export const WsMessages = {
    CHECK_ROOM_WS_MESSAGE: 'checkRoom',
    VALIDATION_ERROR: 'validation_error',
}

class WebsocketService {
    configService;
    _connection = null;

    constructor(configService) {
        this.configService = configService;
    }

    /**
     * Поднять конекшен
     * @returns {Promise<unknown>}
     */
    openConnect() {
        return new Promise((resolve, reject) => {
            const wsUrl = this.configService.getConfig()?.GAME_SERVER_URL ?? '';
            if (!wsUrl) {
                return reject('Нет урла game-server в конфиг сервисе');
            }

            try {
                this.connection = new WebSocket(wsUrl);

                // Если конекшен не поднялся удаляем его и поднимаем ошибку
                this.connection.onerror = () => {
                    this.closeConnect();
                    reject('Ошибка сервера');
                };

                // Если конекшен сразу же закрылся поднимаем ошибку и закрываем его снова
                this.connection.onclose = () => {
                    this.closeConnect();
                    reject('Закрыто соединение с сервером');
                }

                // На успешное открытие поднимаем успех
                this.connection.onopen = () => {
                    resolve(true);
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Закрыть соединение
     * @returns {Promise<void>}
     */
    async closeConnect() {
        if (!this.connection) {
            return;
        }

        try {
            await this.connection.close();
            this.connection = null;
        } catch (e) {
            console.log(e);
        } finally {
            this.connection = null;
        }
    }

    get connection() {
        return this._connection;
    }

    set connection(connect) {
        this._connection = connect;
    }

    /**
     * Отправить сообщение по конекшену
     * @param type
     * @param data
     */
    sendMassage(type, data) {
        if (!this.connection) {
            console.log('Нет соединения с сервером');
            return;
        }
        this.connection.send(JSON.stringify({
            type,
            data,
            language: 'en'
        }));
    }
}

const webSocketService = new WebsocketService(configService);

export default webSocketService;
