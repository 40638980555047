import configService from "./config-service";
import {WsMessages} from "./websocket-service";

class MobileConnectService {
    ERROR_CLASS = 'has-error';
    form;
    codeField;
    errorTextHolder;
    configService;
    webSocketService;
    isLoading = false;

    constructor(configService, webSocketService) {
        this.configService = configService;
        this.webSocketService = webSocketService;

        this.form = document.querySelector('.js-connect-form');
        this.errorTextHolder = this.form.querySelector('.js-error-text');
        this.codeField = this.form.querySelector('.js-code');

        this.submitHandler = this.submitHandler.bind(this);

        this.init();
    }

    init() {
        this.form.addEventListener('submit', this.submitHandler);
        this.form.addEventListener('input' , () => {
           this.clearError();
        });
    }

    getMobileHost() {
        return `${this.configService.getConfig()?.MOBILE_BASE_URL ?? ''}room/`
    }

    setError(errorMessage) {
        this.errorTextHolder.innerHTML = errorMessage;
        this.form.classList.add(this.ERROR_CLASS);
    }

    clearError() {
        this.errorTextHolder.innerHTML = '';
        this.form.classList.remove(this.ERROR_CLASS);
    }

    validation() {
        return !!this.codeField.value;
    }

    validationWs(code) {
        return new Promise(async (resolve, reject) => {
            try {
                // Поднимаем коннекшен
                await this.webSocketService.openConnect();

                // Если конекшен поднят ждём сообщения
                this.webSocketService.connection.onmessage = e => {
                    const data = JSON.parse(e.data);

                    // Если есть сообщение о валидации и есть ошибка в поле входа, показываем ошибку
                    if (
                        data.type === WsMessages.VALIDATION_ERROR
                        && data.data?.errors
                        && data.data.errors?.roomId) {
                        reject(data.data.errors?.roomId);
                    }

                    if (data.type === WsMessages.CHECK_ROOM_WS_MESSAGE && data.data?.success) {
                        resolve(true);
                    }

                    this.webSocketService.closeConnect();
                };


                this.webSocketService.sendMassage(WsMessages.CHECK_ROOM_WS_MESSAGE, {
                    roomId: code
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async submitHandler(e) {
        e.preventDefault();

        // Если идет загрузка не нужно ничего делать
        if (this.isLoading) {
            return;
        }

        this.clearError();

        // Если не проходит валидацию на фронте показываем ошибку
        if (!this.validation()) {
            this.setError('Enter the code to enter');
            return;
        }

        try {
            this.isLoading = true;
            this.form.classList.add('loading');
            const code = this.codeField.value.toUpperCase();
            await this.validationWs(code);
            document.location.href = `${this.getMobileHost()}${this.codeField.value.toUpperCase()}`;

        } catch (e) {
            this.setError(e);
        } finally {
            this.isLoading = false;
            this.form.classList.remove('loading');
        }
    }
}

export default MobileConnectService;
